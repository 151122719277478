import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import SearchComponent from '../containers/Search';
import Banner from '../components/Slider/Banner';
import { IProduct } from '../models/product';
import useSWR from 'swr';
import { getProductList } from '../api/product';
import Error from '../components/Common/Error';

const Search = () => {
    const [cars, setCars] = useState<IProduct[]>([]);
    const [searching, setSearching] = useState(false);
    const [page, setPage] = useState(1);
    const [totalItem, setTotalItem] = useState(0);
    const [pageSize] = useState(12);
    const [searchKeyword, setSearchKeyword] = useState('');
    const { data, error } = useSWR(
        `products?page=${page}&size=${pageSize}&searchKey=${searchKeyword}&status=selling&isDeleted=false`,
        () => {
            return getProductList(
                page,
                pageSize,
                searchKeyword,
                'selling',
                false
            );
        }
    );
    useEffect(() => {
        if (data) {
            setCars(data.result);
            setTotalItem(data.meta?.total_record);
        }
    }, [data]);

    useEffect(() => {
        if (searchKeyword) {
            setSearching(true);
        }
    }, [searchKeyword]);

    useEffect(() => {
        if (data && !cars) {
            setCars(data.result);
        }
    }, [data, cars]);

    if (error) {
        return <Error />;
    }
    return (
        <>
            <Helmet>
                <meta charSet='utf-8' />
                <title>Tìm kiếm - Salon Ô tô Hà Nội 1</title>
            </Helmet>
            <Banner />
            <SearchComponent
                cars={cars}
                setSearchKeyword={setSearchKeyword}
                searching={searching}
                totalItem={totalItem}
                setPage={setPage}
                pageSize={pageSize}
                setSearching={setSearching}
                searchKeyword={searchKeyword}
            />
        </>
    );
};

export default Search;
