import { BsChevronDown, BsSearch } from 'react-icons/bs';
import { IProduct } from '../../models/product';
import GridLayout from '../../layout/GridLayout';
import CarInfo from './CarInfo';
import ReactPaginate from 'react-paginate';
import Loading from '../../components/Common/Loading';
import { FC } from 'react';

interface SearchComponentProps {
    cars: IProduct[];
    searching: boolean;
    setSearchKeyword: Function;
    totalItem: number;
    setPage: Function;
    pageSize: number;
    setSearching: Function;
    searchKeyword: string;
}

const SearchComponent: FC<SearchComponentProps> = ({
    cars,
    searching,
    setSearchKeyword,
    totalItem,
    setPage,
    pageSize,
    setSearching,
    searchKeyword,
}) => {
    const pageCount = Math.ceil(totalItem / pageSize);

    const handleChangePage = (current: number) => {
        setPage(current);
    };

    return (
        <>
            {!cars ? (
                <Loading />
            ) : (
                <>
                    <div className='pt-[60px]'>
                        <div className='container rounded-sm'>
                            <div className='border-b border-main pb-[60px]'>
                                <h1 className='font-bold text-base uppercase mb-2'>
                                    Tìm xe
                                </h1>
                                <div className='w-full relative search'>
                                    <input
                                        type='text'
                                        className={`bg-main w-full py-[10px] pl-4 pr-[60px] focus:outline focus:outline-1 focus:outline-top-navbar rounded-sm`}
                                        placeholder='Tìm kiếm xe ...'
                                        onFocus={() => setSearching(true)}
                                        onBlur={() => {
                                            if (!searchKeyword) {
                                                setSearching(false);
                                            }
                                        }}
                                        onChange={(e) =>
                                            setSearchKeyword(e.target.value)
                                        }
                                    />
                                    <div className='absolute right-0 top-0 bottom-0 flex items-center w-[44px] justify-center rounded-tr-sm rounded-br-sm cursor-pointer'>
                                        <BsSearch
                                            className={`relative z-[1] search-icon text-sm ${
                                                searching
                                                    ? 'text-search-icon'
                                                    : ''
                                            }`}
                                        />
                                        <div
                                            className={`search-icon-bg absolute bg-top-navbar ${
                                                searching
                                                    ? 'left-0'
                                                    : 'left-[40px]'
                                            }  right-0 top-0 bottom-0 flex items-center justify-center z-[0] ease-linear duration-300`}
                                        ></div>
                                    </div>
                                </div>
                            </div>

                            <div className='mt-[20px]'>
                                <GridLayout col={3} gapX={30} gapY={30}>
                                    {cars.map((car: IProduct) => {
                                        return (
                                            <CarInfo car={car} key={car.id} />
                                        );
                                    })}
                                </GridLayout>
                                <ReactPaginate
                                    breakLabel='...'
                                    nextLabel={
                                        <BsChevronDown className='rotate-[-90deg]' />
                                    }
                                    onPageChange={(selectedItem) => {
                                        handleChangePage(
                                            +selectedItem.selected + 1
                                        );
                                    }}
                                    pageRangeDisplayed={5}
                                    pageCount={pageCount}
                                    previousLabel={
                                        <BsChevronDown className='rotate-90' />
                                    }
                                    containerClassName='pagination mt-[40px]'
                                    pageLinkClassName='page-num'
                                    previousLinkClassName='page-num'
                                    nextLinkClassName='page-num'
                                    activeLinkClassName='active'
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default SearchComponent;
