import { FC } from 'react';
import { AiTwotoneBoxPlot } from 'react-icons/ai';
import { IoIosTime } from 'react-icons/io';
import { IoSpeedometer } from 'react-icons/io5';
import { MdSettings } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { IProduct } from '../../models/product';
import { ROUTES } from '../../routes/routes';
import { changePriceValue } from '../../utils';
import { imgNotFound } from '../../utils/constants';

interface CarInfoProps {
    car: IProduct;
}

const CarInfo: FC<CarInfoProps> = ({ car }) => {
    return (
        <Link
            to={`${ROUTES.carDetail}/${car.slug}`}
            className='bg-white drop-shadow-lg p-4 rounded-sm'
            key={car.id}
        >
            <div className='w-full'>
                <img
                    src={
                        process.env.REACT_APP_URL_UPLOAD + car.thumbnail ??
                        imgNotFound
                    }
                    alt={car.ten}
                    className='rounded-sm object-cover h-[240px]'
                />
            </div>
            <div title={car.brand.ten + ' ' + car.ten}>
                <h1 className='font-bold text-[18px] md:text-xl mt-2'>
                    {car.brand.ten + ' ' + car.ten}
                </h1>
                <p className='my-2'>
                    <span className='mr-2'>Giá:</span>
                    <span className='text-txt-highlight font-semibold'>
                        {changePriceValue(car.gia)}
                    </span>
                </p>
                <div className='flex flex-wrap items-center justify-between max-w-[460px]'>
                    <div className='flex items-center text-sm text-third'>
                        <IoSpeedometer />
                        <p className='ml-1'>
                            {(+car.odo).toLocaleString()}
                            <span className='ml-1'>km</span>
                        </p>
                    </div>
                    <div className='flex items-center text-sm text-third'>
                        <IoIosTime />
                        <p className='ml-1'>{car.nam_san_xuat}</p>
                    </div>
                    <div className='flex items-center text-sm text-third'>
                        <AiTwotoneBoxPlot />
                        <p className='ml-1'>
                            {(+car.dung_tich).toFixed(1)}
                            <span>L</span>
                        </p>
                    </div>
                    <div className='flex items-center text-sm text-third'>
                        <MdSettings />
                        <p className='ml-1'>{car.hop_so}</p>
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default CarInfo;
