export const changePriceValue = (price: number | null) => {
    if (price) {
        if (price >= 1000000 && price < 1000000000) {
            return price / 1000000 + ' triệu';
        }
        if (price >= 1000000000) {
            return price / 1000000000 + ' tỷ';
        } else {
            return price + '';
        }
    }
};
