export const segments = [
    {
        name: 'Xe đang bán',
        value: 1,
        status: 'selling',
    },
    {
        name: 'Xe sắp về',
        value: 2,
        status: 'coming',
    },
    {
        name: 'Đã bán gần đây',
        value: 3,
        status: 'sold',
    },
    {
        name: 'Tìm kiếm xe',
        value: -1,
        status: null,
    },
];
