import React from 'react';
import MenuItem from './MenuItem';
// import Logo from './Logo';
import { menus } from './menu';

const Menu = () => {
    return (
        <header className='leading-navbar min-h-navbar font-["Montserrat"]'>
            <div className='max-w-[1600px] px-[40px] lg:px-[100px]'>
                <div className='flex w-full'>
                    <div className='w-full min-h-navbar'>
                        <nav className='flex items-center justify-end'>
                            {/* <Logo width='auto' height={60} /> */}
                            <ul className='flex items-center justify-start'>
                                {menus.map((menu) => {
                                    return (
                                        <MenuItem item={menu} key={menu.name} />
                                    );
                                })}
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Menu;
