import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { segments } from './home';
import GridLayout from '../../layout/GridLayout';
import { ROUTES } from '../../routes/routes';
import useSWR from 'swr';
import { getProductList } from '../../api/product';
import { IProduct } from '../../models/product';
import Error from '../../components/Common/Error';
import CarInfo from '../Search/CarInfo';
import Loading from '../../components/Common/Loading';

const HomeComponent = () => {
    const [chooseSegment, setChooseSegment] = useState(1);
    const [cars, setCars] = useState<IProduct[]>([]);
    const [status, setStatus] = useState('selling');
    const { data, error } = useSWR(
        `products?page=1&size=12&status=${status}`,
        () => {
            return getProductList(1, 12, '', status);
        }
    );

    useEffect(() => {
        if (data) {
            setCars(data.result);
        }
    }, [data]);

    useEffect(() => {
        if (data && !cars) {
            setCars(data.result);
        }
    }, [data, cars]);

    if (error) {
        return <Error />;
    }
    return (
        <>
            {!data ? (
                <Loading />
            ) : (
                <>
                    <div className='w-full mt-0 md:mt-navbar bg-main pb-[60px] min-h-screen'>
                        <div className='relative' id='top'>
                            <video
                                autoPlay
                                muted
                                loop
                                id='bg-video'
                                className='min-w-full min-h-screen max-w-full max-h-full object-cover z-[-1]'
                                src='./images/video.mp4'
                            />

                            <div className='absolute bg-third top-0 left-0 right-0 bottom-0 w-full'>
                                <div className='absolute w-4/5 top-0 right-0 left-0 bottom-0 flex items-center justify-center mx-auto'>
                                    <div className='text-center text-white'>
                                        <h6 className='text-xl'>
                                            Salon Ô tô Hà Nội 1
                                        </h6>
                                        <h2 className='text-[40px] sm:text-[60px] lg:text-[76px] font-extrabold uppercase'>
                                            <span className='text-txt-highlight'>
                                                Cửa hàng bán xe
                                            </span>{' '}
                                            tốt nhất
                                        </h2>
                                        <div className='mt-4'>
                                            <Link
                                                to='/contact'
                                                className='bg-txt-highlight py-3 px-4 rounded-sm main-button'
                                            >
                                                Liên hệ ngay
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='bg-top-navbar'>
                                <div className='container pt-[56px]'>
                                    <div className='flex items-center gap-1'>
                                        {segments.map((segment) => {
                                            return (
                                                <Fragment key={segment.name}>
                                                    {segment.value === -1 ? (
                                                        <Link
                                                            key={segment.name}
                                                            to={ROUTES.search}
                                                            className={`${
                                                                chooseSegment ===
                                                                segment.value
                                                                    ? 'bg-white text-top-navbar'
                                                                    : 'bg-main text-txt-third'
                                                            } px-2 sm:px-5 py-3 font-bold uppercase cursor-pointer rounded-sm text-xs sm:text-sm hover:bg-white hover:text-top-navbar`}
                                                        >
                                                            {segment.name}
                                                        </Link>
                                                    ) : (
                                                        <div
                                                            key={segment.name}
                                                            className={`${
                                                                chooseSegment ===
                                                                segment.value
                                                                    ? 'bg-white text-top-navbar'
                                                                    : 'bg-main text-txt-third'
                                                            } px-2 sm:px-5 py-3 font-bold uppercase cursor-pointer rounded-sm text-xs sm:text-sm hover:bg-white hover:text-top-navbar`}
                                                            onClick={() => {
                                                                setChooseSegment(
                                                                    segment.value
                                                                );
                                                                if (
                                                                    segment.status
                                                                ) {
                                                                    setStatus(
                                                                        segment.status
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            {segment.name}
                                                        </div>
                                                    )}
                                                </Fragment>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className='pt-[60px] container min-h-[400px]'>
                                {cars.length > 0 ? (
                                    <GridLayout col={3} gapX={30} gapY={30}>
                                        {cars?.map((car: IProduct) => {
                                            return (
                                                <CarInfo
                                                    car={car}
                                                    key={car.id}
                                                />
                                            );
                                        })}
                                    </GridLayout>
                                ) : (
                                    'Hiện tại chưa có sản phẩm'
                                )}
                                {chooseSegment === 1 ? (
                                    <div className='w-full flex items-center justify-center mt-[40px]'>
                                        <Link
                                            to={ROUTES.search}
                                            className='p-3 bg-top-navbar text-white font-medium uppercase rounded-md main-button'
                                        >
                                            Tất cả sản phẩm
                                        </Link>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default HomeComponent;
