import React, { FC } from 'react';
import Banner from '../../components/Slider/Banner';
import SliderImage from 'react-zoom-slider';
import { IImage, IProduct } from '../../models/product';
import { imgsNotFound } from '../../utils/constants';
import { changePriceValue } from '../../utils';
import { Link } from 'react-router-dom';
import useInnerWidth from '../../hooks/useInnerWidth';

interface CarDetailComponentProps {
    car: IProduct;
}

const CarDetailComponent: FC<CarDetailComponentProps> = ({ car }) => {
    const { isLaptop } = useInnerWidth();
    const images =
        car?.images?.length > 1
            ? car?.images?.map((image: IImage) => ({
                  image: process.env.REACT_APP_URL_UPLOAD + image?.link,
              }))
            : imgsNotFound;

    function createMarkup() {
        return { __html: car?.mo_ta };
    }

    return (
        <>
            <Banner />
            <div className='py-[60px] min-h-screen'>
                <div className='container'>
                    <div className='mb-[40px] xl:mb-0 w-full relative'>
                        <div className='xl:w-2/3 mr-4 xl:max-w-[660px]'>
                            <div>
                                <h1 className='font-bold text-[32px] mb-[40px]'>
                                    {car?.brand?.ten + ' ' + car?.ten}
                                </h1>
                            </div>
                            <div className='aspect-video'>
                                <iframe
                                    width='100%'
                                    height='100%'
                                    src={`https://www.youtube.com/embed/${car?.youtube_link}`}
                                    frameBorder='0'
                                    className='rounded-sm'
                                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                    allowFullScreen
                                    title={car?.ten}
                                />
                            </div>
                            <div className='mt-[28px]'>
                                {car?.images ? (
                                    <SliderImage
                                        data={images}
                                        showDescription={true}
                                        direction='right'
                                    />
                                ) : null}
                            </div>
                            <div
                                dangerouslySetInnerHTML={createMarkup()}
                                className='mt-[40px] description-custom'
                            />
                            <div className='mt-6 flex items-center gap-6'>
                                {isLaptop ? (
                                    <div>
                                        <Link
                                            to='/contact'
                                            className='bg-txt-highlight text-white py-3 px-4 rounded-sm main-button'
                                        >
                                            Liên hệ ngay
                                        </Link>
                                    </div>
                                ) : (
                                    <div>
                                        <a
                                            href='tel:+84968680977'
                                            className='bg-txt-highlight text-white py-3 px-4 rounded-sm main-button'
                                        >
                                            Liên hệ ngay
                                        </a>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='xl:hidden xl:mt-0 mt-5 mr-2'>
                            <p>Thông số chi tiết:</p>
                        </div>
                        <div className='xl:absolute xl:right-0 xl:top-0 pt-3 pb-3 xl:pb-2 px-6 bg-main max-w-[300px] xl:w-1/3 font-["Montserrat"]'>
                            <div className='flex items-center border-b last:border-0 border-main gap-4 py-2'>
                                <span className='font-bold uppercase text-xs min-w-[120px]'>
                                    Màu nội thất
                                </span>
                                <p
                                    className='min-w-[120px] text-sm font-bold capitalize break-words line-clamp-3 text-[blue]'
                                    title={car?.mau_noi_that}
                                >
                                    {car?.mau_noi_that}
                                </p>
                            </div>
                            <div className='flex items-center border-b last:border-0 border-main gap-4 py-2'>
                                <span className='font-bold uppercase text-xs min-w-[120px]'>
                                    Màu ngoại thất
                                </span>
                                <p
                                    className='min-w-[120px] text-sm font-bold capitalize break-words line-clamp-3 text-[blue]'
                                    title={car?.mau_ngoai_that}
                                >
                                    {car?.mau_ngoai_that}
                                </p>
                            </div>
                            <div className='flex items-center border-b last:border-0 border-main gap-4 py-2'>
                                <span className='font-bold uppercase text-xs min-w-[120px]'>
                                    Phiên bản
                                </span>
                                <p
                                    className='min-w-[120px] text-sm font-bold capitalize break-words line-clamp-3 text-[blue]'
                                    title={car?.phien_ban}
                                >
                                    {car?.phien_ban}
                                </p>
                            </div>
                            <div className='flex items-center border-b last:border-0 border-main gap-4 py-2'>
                                <span className='font-bold uppercase text-xs min-w-[120px]'>
                                    Xuất xứ
                                </span>
                                <p
                                    className='min-w-[120px] text-sm font-bold capitalize break-words line-clamp-3 text-[blue]'
                                    title={car?.xuat_xu}
                                >
                                    {car?.xuat_xu}
                                </p>
                            </div>
                            <div className='flex items-center border-b last:border-0 border-main gap-4 py-2'>
                                <span className='font-bold uppercase text-xs min-w-[120px]'>
                                    Nhiên liệu
                                </span>
                                <p
                                    className='min-w-[120px] text-sm font-bold capitalize break-words line-clamp-3 text-[blue]'
                                    title={car?.nhien_lieu}
                                >
                                    {car?.nhien_lieu}
                                </p>
                            </div>
                            <div className='flex items-center border-b last:border-0 border-main gap-4 py-2'>
                                <span className='font-bold uppercase text-xs min-w-[120px]'>
                                    Hộp số
                                </span>
                                <p
                                    className='min-w-[120px] text-sm font-bold capitalize break-words line-clamp-3 text-[blue]'
                                    title={car?.hop_so}
                                >
                                    {car?.hop_so}
                                </p>
                            </div>
                            <div className='flex items-center border-b last:border-0 border-main gap-4 py-2'>
                                <span className='font-bold uppercase text-xs min-w-[120px]'>
                                    Dung tích
                                </span>
                                <p
                                    className='min-w-[120px] text-sm font-bold capitalize break-words line-clamp-3 text-[blue]'
                                    title={(+car?.dung_tich).toFixed(1) + 'L'}
                                >
                                    {(+car?.dung_tich).toFixed(1) + 'L'}
                                </p>
                            </div>
                            <div className='flex items-center border-b last:border-0 border-main gap-4 py-2'>
                                <span className='font-bold uppercase text-xs min-w-[120px]'>
                                    Biển số
                                </span>
                                <p
                                    className='min-w-[120px] text-sm font-bold capitalize break-words line-clamp-3 text-[blue]'
                                    title={car?.bien_so}
                                >
                                    {car?.bien_so}
                                </p>
                            </div>
                            <div className='flex items-center border-b last:border-0 border-main gap-4 py-2'>
                                <span className='font-bold uppercase text-xs min-w-[120px]'>
                                    ODO
                                </span>
                                <p
                                    className='min-w-[120px] text-sm font-bold break-words line-clamp-3 text-[blue]'
                                    title={car?.odo}
                                >
                                    {(+car?.odo).toLocaleString() +
                                        (car?.odo_type
                                            ? ` ${car?.odo_type}`
                                            : ' km')}
                                </p>
                            </div>
                            <div className='flex items-center border-b last:border-0 border-main gap-4 py-2'>
                                <span className='font-bold uppercase text-xs min-w-[120px]'>
                                    Năm sản xuất
                                </span>
                                <p
                                    className='min-w-[120px] text-sm font-bold capitalize break-words line-clamp-3 text-[blue]'
                                    title={car?.nam_san_xuat}
                                >
                                    {car?.nam_san_xuat}
                                </p>
                            </div>
                            <div className='flex items-center border-b last:border-0 border-main gap-4 py-2'>
                                <span className='font-bold uppercase text-xs min-w-[120px]'>
                                    Giá bán
                                </span>
                                <p
                                    className='min-w-[120px] text-sm font-bold capitalize break-words line-clamp-3 text-[blue]'
                                    title={changePriceValue(car?.gia)}
                                >
                                    {changePriceValue(car?.gia)}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CarDetailComponent;
