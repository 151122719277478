export const img404 = 'https://i.imgur.com/oRBZxTV.png';

export const imgNotFound =
    'https://www.salonlfc.com/wp-content/uploads/2018/01/image-not-found-scaled.png';

export const imgsNotFound = [
    {
        image: 'https://www.salonlfc.com/wp-content/uploads/2018/01/image-not-found-scaled.png',
    },
];

export const avatarDefault =
    'https://avatar-ex-swe.nixcdn.com/avatar/2022/08/23/b/8/3/d/1661244178639.jpg';
