import React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { getProductBySlug } from '../../api/product';
import Error from '../../components/Common/Error';
import Loading from '../../components/Common/Loading';
import CarDetailComponent from '../../containers/Detail/CarDetail';

const CarDetail = () => {
    const { slug } = useParams();

    const { data, error } = useSWR(`/product/${slug}`, () =>
        getProductBySlug(slug)
    );

    if (error) {
        return <Error />;
    }

    return (
        <div className='min-h-screen'>
            {!data ? (
                <Loading />
            ) : (
                <>
                    <Helmet>
                        <meta charSet='utf-8' />
                        <meta
                            property='og:title'
                            content={data?.brand?.ten + ' ' + data?.ten}
                        />
                        <meta property='og:image' content={data?.thumbnail} />
                        <meta property='og:description' content={data?.mo_ta} />
                        <title>
                            Chi tiết xe {data.ten} - Salon Ô tô Hà Nội 1
                        </title>
                    </Helmet>
                    <CarDetailComponent car={data} />
                </>
            )}
        </div>
    );
};

export default CarDetail;
