import { client } from './client';

export const getProductList = async (
    page?: number,
    size?: number,
    searchKey?: string,
    status?: string,
    isDeleted?: boolean
) => {
    const res = await client.get('/products', {
        params: {
            page,
            size,
            searchKey,
            status,
            isDeleted,
        },
    });

    return res.data.data;
};

export const getProductBySlug = async (slug: string | undefined) => {
    const res = await client.get(`products/${slug}`);

    return res.data.data;
};
